import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PrivacyPolicy from '../components/PrivacyPolicy.vue'
import StandaloneVideo from '../views/StandaloneVideo.vue'
import StandaloneGuide from '../views/StandaloneGuide.vue'
import NjhHOME from '../views/NJH_Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    title: 'Project CONNECT',
    component: Home
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    title: 'LungScreen Privacy Policy',
    component: PrivacyPolicy
  },
  {
    path: '/:referral/:userState',
    name: 'HomeQuitLine',
    title: 'Project CONNECT',
    component: Home
  },
  {
    path: '/:userState',
    name: 'HomePrint',
    title: 'Project CONNECT',
    component: Home
  },
  {
    path: '/embed/standalonevideo/:parameter',
    name: 'StandaloneVideo',
    title: 'Stand Alone Video',
    component: StandaloneVideo
  },
  {
    path: '/embed/guide/:parameter',
    name: 'StandaloneGuide',
    title: 'Stand Alone Guide',
    component: StandaloneGuide
  },
  {
    path: '/embed/njh/:userState',
    name: 'NjhHOME',
    title: 'NJH',
    component: NjhHOME
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: 'process.env.VUE_APP_BASE_ROUTER_URL',
  base: process.env.VUE_APP_BASE_ROUTER_URL,
  routes
})

export default router
