<template>
  <div>
    <video :style="videoStyle" ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from 'video.js'

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    videoStyle: null
  },
  data () {
    return {
      player: null
    }
  },
  mounted () {
    this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady () {
      // console.log('onPlayerReady', this)
    })
    function playHandler () {
      // console.log('play', this.player.currentTime)
      const eventObject = {}
      eventObject.timeStamp = Date.now()
      const currentdate = new Date()
      const datetime = currentdate.getDate() + '/' +
                (currentdate.getMonth() + 1) + '/' +
                currentdate.getFullYear() + ' @ ' +
                currentdate.getHours() + ':' +
                currentdate.getMinutes() + ':' +
                currentdate.getSeconds()
      eventObject.datetime = datetime
      // window.ga(function (tracker) {
      //   window.clientId = tracker.get('clientId')
      // })
      // eventObject.clientId = window.clientId
      // window.ga('send', {
      //   hitType: 'event',
      //   eventCategory: 'Video',
      //   eventAction: 'Started',
      //   eventLabel: JSON.stringify(eventObject)
      // })
    }
    function pauseHandler () {
      // console.log('pause', this.player.currentTime)
    }
    function finishHandler (vm) {
      // console.log('ended', this.player.currentTime)
      vm.$emit('videoEvent', 'FINISHED')
    }
    function startHandler (vm) {
      // console.log('ended', this.player.currentTime)
      vm.$emit('videoEvent', 'STARTED')
    }

    function progressHandler (vm) {
      // console.log('ended', this.player.currentTime)
      vm.$emit('videoEvent', 'PROGRESS')
    }
    this.player.on('play', () => {
      startHandler(this)
    })
    this.player.on('timeupdate', () => {
      progressHandler(this)
    })
    this.player.on('pause', pauseHandler)
    this.player.on('ended', () => {
      finishHandler(this)
    })
  },
  beforeDestroy () {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>
