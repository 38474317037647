<template>
  <div id="main-container" :style="styleContainer">
    <nav v-if="shouldShowHeader" class="custom-navbar" id="pnavbar">
        <div class="">
            <div id="logo-container" style="width: 18%;">
                <a class="navbar-brand" id="logo" href="/">
                    <!--<img src="images/logo_original.svg">-->
                    <img :src="this.baseURL+'images/Project-Connect-ID.png'">
                </a>
            </div>
            <div class="custom-navbar-header" id="toggle-container">

                 <b-button class="custom-navbar-toggle"  v-b-toggle.responsive-collapsible-menu id="show-menu" aria-expanded="false" ><span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span></b-button>
            </div>

              <div class="custom-navbar-collapse" id="custom-navbar-collapse"  >
                 <b-collapse id="responsive-collapsible-menu"  aria-expanded="true">
                              <ul class="nav navbar-nav" id="topMenu">
                                  <!-- <li><router-link to="/">Go to Bar</router-link></li> -->
                                  <li><a href="#indexPage">Is lung cancer screening right for me?</a></li>
                                  <li><a href="#eligibilityPage">Am I eligible for lung cancer screening?</a></li>
                                  <li><a href="#discussPage">Talking with Your Doctor About Lung Cancer Screening</a></li>
                                  <li><a href="#riskPage">What is your lung cancer risk?</a></li>
                                  <li><a href="#screnningPage">Where can I get screened?</a></li>
                              </ul>
                              </b-collapse>
              </div>

        </div>
    </nav>
    <router-view/>

  </div>
</template>
<script>

export default {
  name: 'App',
  data: function () {
    return { shouldShowHeader: true }
  },
  components: {
  },

  mounted () {
    // window.addEventListener('scroll', this.scrollFunction)
    const routeName = this.$route.name
    // console.log(routeName)
    if (routeName === 'StandaloneVideo' || routeName === 'NjhHOME') { this.shouldShowHeader = false } else { this.shouldShowHeader = true }

    /* console.log(process.env.VUE_APP_BASE_ROUTER_URL) */
  },
  methods: {
    scrollFunction: function () {
      // const height = $(window).scrollTop()
      // if (height > 100) {
      //   $('#back2Top').fadeIn()
      // } else {
      //   $('#back2Top').fadeOut()
      // }
      // console.log(process.env.BASE_URL)
      // if (window.scrollY > 60) {
      //   const element = document.getElementById('topMenu')
      //   const classes = element.className.split(' ')
      //   const i = classes.indexOf('zoom-in')

      //   if (i >= 0) { classes.splice(i, 1) }

      //   element.className = classes.join(' ')
      //   document.getElementById('logo-container').style.width = '14%'
      // } else {
      //   const element = document.getElementById('topMenu')
      //   const classes = element.className.split(' ')
      //   const i = classes.indexOf('zoom-in')

      //   if (i < 0) { classes.push('zoom-in') }

      //   element.className = classes.join(' ')
      //   document.getElementById('logo-container').style.width = '19%'
      // }
    }
  },
  computed: {
    baseURL: () => process.env.VUE_APP_BASE_ROUTER_URL,
    styleContainer () { return !this.shouldShowHeader ? { width: '99%' } : { width: '100%' } }
  }
}
</script>
<style lang="scss">
@import "public/sass/site.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
