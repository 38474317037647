<template>

  <video-player :videoStyle="videoStyle" style="width:100%;height:100%" :options="videoOptions" v-on:video-event="handleVideoEvent"/>

</template>

<script>
import VideoPlayer from '@/components/EmbeddableVideoPlayer'
import axios from 'axios'
export default {
  name: 'StandAloneVideoPlayer',
  components: { VideoPlayer },
  mounted () {
    // console.log(process.env)
    this.handlePageloadedEvent()
  },
  data: function () {
    return {
      lastProgress: 0,
      progressTime: 0,
      videoOptions: {
        // autoplay: true,
        controls: true,
        responsive: true,
        sources: [
          {
            src:
                process.env.VUE_APP_BASE_ROUTER_URL + 'videos/vid_v2/playlist.m3u8',
            type: 'application/x-mpegURL'
          }
        ],
        poster: process.env.VUE_APP_BASE_ROUTER_URL + 'images/vid_thumnail2.jpg'
      },
      videoStyle: { width: '100%', height: '100%' },
      states: [
        {
          short: 'AL',
          name: 'Alabama',
          country: 'US'
        },
        {
          short: 'AK',
          name: 'Alaska',
          country: 'US'
        },
        {
          short: 'AZ',
          name: 'Arizona',
          country: 'US'
        },
        {
          short: 'AR',
          name: 'Arkansas',
          country: 'US'
        },
        {
          short: 'CA',
          name: 'California',
          country: 'US'
        },
        {
          short: 'CO',
          name: 'Colorado',
          country: 'US'
        },
        {
          short: 'CT',
          name: 'Connecticut',
          country: 'US'
        },
        {
          short: 'DC',
          name: 'District of Columbia',
          alt: ['Washington DC', 'Washington D.C.'],
          country: 'US'
        },
        {
          short: 'DE',
          name: 'Delaware',
          country: 'US'
        },
        {
          short: 'FL',
          name: 'Florida',
          country: 'US'
        },
        {
          short: 'GA',
          name: 'Georgia',
          country: 'US'
        },
        {
          short: 'HI',
          name: 'Hawaii',
          country: 'US'
        },
        {
          short: 'ID',
          name: 'Idaho',
          country: 'US'
        },
        {
          short: 'IL',
          name: 'Illinois',
          country: 'US'
        },
        {
          short: 'IN',
          name: 'Indiana',
          country: 'US'
        },
        {
          short: 'IA',
          name: 'Iowa',
          country: 'US'
        },
        {
          short: 'KS',
          name: 'Kansas',
          country: 'US'
        },
        {
          short: 'KY',
          name: 'Kentucky',
          country: 'US'
        },
        {
          short: 'LA',
          name: 'Louisiana',
          country: 'US'
        },
        {
          short: 'ME',
          name: 'Maine',
          country: 'US'
        },
        {
          short: 'MD',
          name: 'Maryland',
          country: 'US'
        },
        {
          short: 'MA',
          name: 'Massachusetts',
          country: 'US'
        },
        {
          short: 'MI',
          name: 'Michigan',
          country: 'US'
        },
        {
          short: 'MN',
          name: 'Minnesota',
          country: 'US'
        },
        {
          short: 'MS',
          name: 'Mississippi',
          country: 'US'
        },
        {
          short: 'MO',
          name: 'Missouri',
          country: 'US'
        },
        {
          short: 'MT',
          name: 'Montana',
          country: 'US'
        },
        {
          short: 'NE',
          name: 'Nebraska',
          country: 'US'
        },
        {
          short: 'NV',
          name: 'Nevada',
          country: 'US'
        },
        {
          short: 'NH',
          name: 'New Hampshire',
          country: 'US'
        },
        {
          short: 'NJ',
          name: 'New Jersey',
          country: 'US'
        },
        {
          short: 'NM',
          name: 'New Mexico',
          country: 'US'
        },
        {
          short: 'NY',
          name: 'New York',
          country: 'US'
        },
        {
          short: 'NC',
          name: 'North Carolina',
          country: 'US'
        },
        {
          short: 'ND',
          name: 'North Dakota',
          country: 'US'
        },
        {
          short: 'OH',
          name: 'Ohio',
          country: 'US'
        },
        {
          short: 'OK',
          name: 'Oklahoma',
          country: 'US'
        },
        {
          short: 'OR',
          name: 'Oregon',
          country: 'US'
        },
        {
          short: 'PA',
          name: 'Pennsylvania',
          country: 'US'
        },
        {
          short: 'RI',
          name: 'Rhode Island',
          country: 'US'
        },
        {
          short: 'SC',
          name: 'South Carolina',
          country: 'US'
        },
        {
          short: 'SD',
          name: 'South Dakota',
          country: 'US'
        },
        {
          short: 'TN',
          name: 'Tennessee',
          country: 'US'
        },
        {
          short: 'TX',
          name: 'Texas',
          country: 'US'
        },
        {
          short: 'UT',
          name: 'Utah',
          country: 'US'
        },
        {
          short: 'VT',
          name: 'Vermont',
          country: 'US'
        },
        {
          short: 'VA',
          name: 'Virginia',
          country: 'US'
        },
        {
          short: 'WA',
          name: 'Washington',
          country: 'US'
        },
        {
          short: 'WV',
          name: 'West Virginia',
          country: 'US'
        },
        {
          short: 'WI',
          name: 'Wisconsin',
          country: 'US'
        },
        {
          short: 'WY',
          name: 'Wyoming',
          country: 'US'
        },
        {
          short: 'AS',
          name: 'American Samoa',
          country: 'US'
        },
        {
          short: 'GU',
          name: 'Guam',
          country: 'US'
        },
        {
          short: 'MP',
          name: 'Northern Mariana Islands',
          country: 'US'
        },
        {
          short: 'PR',
          name: 'Puerto Rico',
          country: 'US'
        },
        {
          short: 'UM',
          name: 'United States Minor Outlying Islands',
          country: 'US'
        },
        {
          short: 'VI',
          name: 'Virgin Islands',
          country: 'US'
        }],
      selectedState: {}
    }
  },
  methods: {
    handlePageloadedEvent: function () {
      let eventData = {}
      this.videoFinished = false
      const eventObject = {}
      eventObject.timeStamp = Date.now()
      const currentdate = new Date()
      const datetime = currentdate.getDate() + '/' +
                (currentdate.getMonth() + 1) + '/' +
                currentdate.getFullYear() + ' @ ' +
                currentdate.getHours() + ':' +
                currentdate.getMinutes() + ':' +
                currentdate.getSeconds()
      eventObject.datetime = datetime
      eventObject.originReferer = this.$route.params.parameter
      this.selectedState = this.isValidState(eventObject.originReferer)
      // window.ga(function (tracker) {
      //   window.clientId = tracker.get('clientId')
      // })
      eventObject.clientId = window.clientId
      eventObject.selectedState = this.selectedState
      eventObject.origin = 'embeddedVideo'
      eventData = {
        hitType: 'event',
        eventCategory: 'EmbeddedVideo',
        eventAction: 'Loaded',
        eventLabel: JSON.stringify(eventObject)
      }
      try {
        return axios.post(process.env.VUE_APP_API_BASE_URL + 'api/connect/logevent', null, { params: { payload: eventData } })
          .then(function (result) { return result })
          .catch(function (result) { return result })
      } catch (error) {
        // console.log(error)
      }
    },
    isValidState (param) {
      if (!param) { return false }
      const state = this.states.find(function (e) {
        return param.toUpperCase() === e.short
      }, this)
      const customState = {
        short: param,
        country: 'unknown',
        name: param
      }
      return state !== undefined ? state : customState
    },
    handleVideoEvent: function (event) {
      // console.log(event)
      if (event === 'PROGRESS') {
        if ((Date.now() - this.lastProgress) > 30000) {
          // console.log('sending progress event')
          this.progressTime = this.lastProgress === 0 ? 0 : this.progressTime + 30000
          this.lastProgress = Date.now()
          let eventData = {}
          const eventObject = {}
          eventObject.timeStamp = Date.now()
          const currentdate = new Date()
          const datetime = currentdate.getDate() + '/' +
                (currentdate.getMonth() + 1) + '/' +
                currentdate.getFullYear() + ' @ ' +
                currentdate.getHours() + ':' +
                currentdate.getMinutes() + ':' +
                currentdate.getSeconds()
          eventObject.datetime = datetime
          eventObject.progressTime = this.progressTime
          // window.ga(function (tracker) {
          //   window.clientId = tracker.get('clientId')
          // })
          eventObject.clientId = window.clientId
          eventObject.origin = 'embeddedVideo'
          eventData = {
            hitType: 'event',
            eventCategory: 'EmbeddedVideo',
            eventAction: event,
            eventLabel: JSON.stringify(eventObject)
          }
          try {
            return axios.post(process.env.VUE_APP_API_BASE_URL + 'api/connect/logevent', null, { params: { payload: eventData } })
              .then(function (result) { return result })
              .catch(function (result) { return result })
          } catch (error) {
            // console.log(error)
          }
        }
      } else {
        let eventData = {}
        this.videoFinished = event === 'FINISHED'
        const eventObject = {}
        eventObject.timeStamp = Date.now()
        const currentdate = new Date()
        const datetime = currentdate.getDate() + '/' +
                (currentdate.getMonth() + 1) + '/' +
                currentdate.getFullYear() + ' @ ' +
                currentdate.getHours() + ':' +
                currentdate.getMinutes() + ':' +
                currentdate.getSeconds()
        eventObject.datetime = datetime
        eventObject.originReferer = this.$route.params.parameter
        this.selectedState = this.isValidState(eventObject.originReferer)
        eventObject.origin = 'embeddedVideo'
        // window.ga(function (tracker) {
        //   window.clientId = tracker.get('clientId')
        // })
        eventObject.clientId = window.clientId
        eventObject.selectedState = this.selectedState
        eventData = {
          hitType: 'event',
          eventCategory: 'EmbeddedVideo',
          eventAction: event,
          eventLabel: JSON.stringify(eventObject)
        }
        try {
          return axios.post(process.env.VUE_APP_API_BASE_URL + 'api/connect/video-tracking', null, { params: { payload: eventData } })
            .then(function (result) { return result })
            .catch(function (result) { return result })
        } catch (error) {
        // console.log(error)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
