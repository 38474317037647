<template>
  <div class="mainContent" id="njh-main-content">
    <modal name="disclaimer-modal"
           :classes="['disclaimer-modal', (hasBugs&&modalCloseAttempt) && 'has-bugs']"
           :width="420"
           :adaptive="true"
           :clickToClose="false"
           @before-open="beforeOpen"
           @before-close="beforeClose">
      <div class="disclaimer-modal-content">
        <!--
        <div v-if="this.consentStage === 1" style="padding: 10px;">
          <div>
              <b-form-group label="Please enter the access code and read the disclaimer below" label-for="access-code-input">
              <b-form-input id="access-code-input" required type="password" placeholder="Access Code" v-model="$v.accessCodeForm.accessCode.$model"></b-form-input>
              <b-form-invalid-feedback v-if="!validateAccessCode('accessCode')" class="error-feedback" id="isValidAccessCode-feedback">Incorrect access code</b-form-invalid-feedback>
              </b-form-group>
          </div>
          {{disclaimerMessage}}
        </div>
        -->
        <div v-if="this.consentStage === 2" style="padding: 10px;">
          <div v-if="this.consentForm.stateFromURL">
            <p>{{content.disclaimerMessage}}<br></p>
          </div>
          <div v-if="this.consentForm.stateFromURL == false">
            <p>{{content.disclaimerMessage}}<br></p>

            <div><p><b>1. Please select your state from the drop down list</b></p></div>
            <v-select label="name"
              v-model="$v.consentForm.selectedState.$model"
              :options="states"
              placeholder="Please select your state">
            </v-select>
            <b-form-invalid-feedback v-if="!validateConsentFormState('selectedState')" class="error-feedback" id="selectedState-feedback">This field is required.</b-form-invalid-feedback>
            <br>

            <div><p><b>2. Were you referred to this website from Quitline services?</b></p></div>
            <b-form-group id="quit-line-referral" label="">
              <b-form-radio-group
                v-model="$v.consentForm.isReferred.$model"
                :options="['No', 'Yes']"
                >
              </b-form-radio-group>
              <b-form-invalid-feedback v-if="!validateConsentFormState('isReferred')" class="error-feedback" id="isFromQuitLine-feedback">This field is required.</b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <!--<sub :style="{opacity: hasBugs && modalCloseAttempt ? 1 : 0, padding:'5px 0px'}">
          Please click on "I understand" to proceed
        </sub>-->
        <a class="btn btn-danger" :style="{margin: '5px 0px'}" @click="consent">{{this.consentStage == 1 ? "submit":"submit"}}</a>
      </div>
  </modal>

    <modal centered name="information-modal"
           :classes="['information-modal']"
           :pivot-y="0.2"
           :width="500"
           :adaptive="false"
           :clickToClose="true">
      <div class="disclaimer-modal-content">
        <p style="text-align:justify" >{{content.informationModalText}}</p>
      <div><a class="btn btn-info" :style="{margin: '5px 0px'}" @click="closeInfoModal">OK</a></div>
      </div>
    </modal>
    <modal name="privacy-modal"
           :classes="['privacy-modal']"
           :pivot-y="0.2"
           :adaptive="false"
           :clickToClose="true">
      <div class="">
        <PrivacyPolicy></PrivacyPolicy>
      <div :style="{textAlign:'center'}"><a class="btn btn-info" :style="{margin: '5px 0px', fontSize:'18px', marginBottom:'10px'}" @click="closePrivacyModal">Accept</a></div>
      </div>
    </modal>
    <b-alert
      v-model="showTopDisclaimer"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;font-size: 18px"
      variant="info"
      dismissible
    >
     <b>Disclaimer: </b> {{content.disclaimerMessageEmbedded}}
    </b-alert>

        <div class="mainContent position-relative" data-spy="scroll" data-target="#topMenu">
            <div class="section">
              <section id="indexPage" class="container body-content">
                <div class="row toppading">
                  <div class="col-sm-12">
                    <h2>{{content.videoSectionTitle}}</h2>
                  </div>
                </div>
                <div class="row toppading2">
                  <div class="col-sm-4">
                    <div style="margin-top:15px; margin-right: 20px;">
                      <p>

                        {{content.videoSectionBody}}
                      </p>

                    </div>
                  </div>
                  <div class="col-sm-8">

                    <video-player :options="videoOptions" v-on:videoEvent="handleVideoEvent"/>
                    <!--                        <iframe title="Making a Decision About Lung Cancer Screening" src="https://www.youtube.com/embed/BwRMCZDRfzM?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" id="fitvid0" style="width:90%;height:400px;"></iframe>-->
                  </div>

                </div>
              </section>
            </div>
            <div id="eligibilityPage" class="section">
              <section  class="container body-content">
                <div class="row toppading">
                  <div class="col-sm-12">
                    <h2>Am I eligible for lung cancer screening?</h2>
                  </div>
                </div>
                <div class="row toppading2">
                  <div class="col-sm-12">
                    <div style="margin-top:15px; margin-right: 20px;">
                      <p>
                        {{content.eligibilitySectionBody}}
                      </p>

                    </div>
                  </div>
                  <!--<div class="col-sm-4 text-center">
                    <div style="width:90%;" class="border-primary justify-content-center">
                      <div>
                        <b-button  v-b-toggle.collapse-4 class="btn btn-primary " id="show-eligibility-btn" aria-expanded="false" aria-controls="controllerCollapsible"><span>Show Calculator</span></b-button>
                      </div>
                    </div>
                  </div>-->

                  <div class="col-sm-12" id="collapse-4"  >
                    <div class="card card-body" id="eligibility-body">
                      <form>
                        <div class="row">
                          <b-form-group class="col-sm-6" id="elig-input-group-9"
                                        :label="content.eligibilityQuestion1">
                            <b-form-radio-group
                              v-model="$v.eligibilityFormModel.lungCancerDiagnosis.$model"
                              :options="[ 'Yes','No']"
                            ></b-form-radio-group>
                            <b-form-invalid-feedback v-if="!validateEligibilityFormState('lungCancerDiagnosis')" class="error-feedback" id="elig-lungCancerDiagnosis-live-feedback">This is a required field.</b-form-invalid-feedback>
                          </b-form-group>
                          <b-form-group class="col-sm-4" id="elig-input-group-5"
                                        :label="content.eligibilityQuestion2">
                            <b-form-radio-group
                              v-model="$v.eligibilityFormModel.currentSmokingStatus.$model"
                              :options="[ 'Yes','No, I quit']"
                            ></b-form-radio-group>
                            <b-form-invalid-feedback v-if="!validateEligibilityFormState('currentSmokingStatus')" class="error-feedback" id="elig-currentSmokingStatus-live-feedback">This is a required field.</b-form-invalid-feedback>
                          </b-form-group>
                        </div>

                        <b-form-group id="elig-input-group-10"
                                      :label="content.eligibilityQuestion3"
                                      v-if="this.eligibilityFormModel.currentSmokingStatus && this.eligibilityFormModel.currentSmokingStatus !== 'Yes'">
                          <b-form-input
                            id="elig-input-10"
                            type="number"
                            min="0"
                            v-model="$v.eligibilityFormModel.yearsSinceQuitSmoking.$model"
                            required
                            placeholder="Enter years since you quit smoking"
                          ></b-form-input>
                          <b-form-invalid-feedback v-if="!validateEligibilityFormState('yearsSinceQuitSmoking')" class="error-feedback" id="elig-yearsSmoked-live-feedback">This is a required field.</b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group id="elig-input-group-6"
                        :label="content.eligibilityQuestion4">
                          <b-form-input
                            id="elig-input-6"
                            type="number"
                            min="0"
                            v-model="$v.eligibilityFormModel.yearsSmoked.$model"
                            required
                            placeholder="Enter years smoked"
                          ></b-form-input>
                          <b-form-invalid-feedback v-if="!validateEligibilityFormState('yearsSmoked')" class="error-feedback" id="elig-yearsSmoked-live-feedback">This is a required field.</b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group id="elig-input-group-7"
                        :label="content.eligibilityQuestion5">
                          <b-form-input
                            id="elig-input-7"
                            type="number"
                            min="0"
                            v-model="$v.eligibilityFormModel.cigPerDay.$model"
                            required
                            placeholder="Enter number of cigarettes"
                          ></b-form-input>
                          <b-form-invalid-feedback v-if="!validateEligibilityFormState('cigPerDay')" class="error-feedback" id="elig-cigPerDay-live-feedback">This is a required field.</b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group id="elig-input-group-8"
                        :label="content.eligibilityQuestion6">
                          <b-form-input
                            id="elig-input-8"
                            type="number"
                            min="0"
                            v-model="$v.eligibilityFormModel.age.$model"
                            required
                            placeholder="Enter your age"
                          ></b-form-input>
                          <b-form-invalid-feedback v-if="!validateEligibilityFormState('age')" class="error-feedback" id="elig-age-live-feedback">This is a required field.</b-form-invalid-feedback>
                        </b-form-group>

                        <div id="elig-submit-button-grp">
                          <b-button  class="btn btn-primary score-form-btn" v-on:click="calculateEligibility" id="elig-calculate-score" ><span>Show Eligibility</span></b-button>
                          <b-button  class="btn btn-warning score-form-btn" id="elig-clear-form" @click.prevent="clearEligibilityForm()" ><span>Clear Form</span></b-button>

                        </div>
                        <div v-if="this.eligibilityResult.calculated" class="result-box">
                          <p> {{content.eligibilityResultHeader}} </p>
                          <ul>
                            <li>
                              <div class="row">
                                <div class="elig-result-row"><font-awesome-icon :icon="this.eligibilityResult.icons.age" /> {{content.eligibilityResultItem1}}</div>
                                <div class="elig-result-row"><font-awesome-icon :icon="this.eligibilityResult.icons.currentSmokingStatus" /> {{content.eligibilityResultItem2}}</div>
                                <div class="elig-result-row"><font-awesome-icon :icon="this.eligibilityResult.icons.packetYear" /> {{content.eligibilityResultItem3}} {{this.eligibilityResult.packetYear}}.</div>
                                <div class="elig-result-row"><font-awesome-icon :icon="this.eligibilityResult.icons.lungCancerDiagnosis" /> {{content.eligibilityResultItem4}}</div>

                              </div>
                            </li>
                          </ul>
                          {{content.eligibilityResultSummary}}
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="section">
              <section id="discussPage" class="container body-content">
                <div class="row toppading">
                  <div class="col-sm-12">
                    <h2>{{content.guideSectionTitle}}</h2>
                  </div>
                </div>
                <div class="row toppading2">
                  <div class="col-sm-8">
                    <div style="margin-top:15px; margin-right: 20px;">
                      <p>
                        {{content.guideSectionBody}}
                      </p>
                    </div>
                  </div>
                  <div class="col-sm-4 text-center">
                    <div class="border-primary justify-content-center" >
                      <div>
                        <a id="download-guide-btn" href="/pdfs/discussion_guide.pdf" v-on:click="buttonClicked('DOWNLOAD_GUIDE')" target="_blank" type="button" class="btn btn-primary btn-secondary section-btn">
                          <span>{{content.guideSectionButton2}}</span>
                        </a>
                        <!-- <button id="inactive-download-guide-btn" hre v-if="!this.videoFinished" type="button" class="btn btn-primary btn-secondary section-btn">
                          <span>{{content.guideSectionButton1}}</span>
                        </button> -->
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="section" v-if="this.excludedStates.indexOf(this.consentForm.selectedState.short)==-1 ">
              <section id="riskPage" class="container body-content">
                <div class="row toppading">
                  <div class="col-sm-12">
                    <h2>{{content.riskCalculatorSectionTitle}}</h2>
                  </div>
                </div>
                <div class="row toppading2">
                  <div class="col-sm-12">
                    <div style="margin-top:15px; margin-right: 20px;">
                      <p>
                        {{content.riskCalculatorSectionBody}}
                      </p>

                    </div>
                  </div>
                  <!--<div class="col-sm-4 text-center">
                      <div style="width:90%;" class="border-primary justify-content-center">
                          <div>
                            <b-button  v-b-toggle.collapse-3 class="btn btn-primary " id="show-calculator-btn" aria-expanded="false" aria-controls="controllerCollapsible"><span>Show Calculator</span></b-button>
                          </div>
                      </div>
                  </div>-->

                  <div class="col-sm-12" id="collapse-3"  >
                    <div class="card card-body" id="calculator-body">
                      <form>
                        <div class="row">
                          <b-form-group id="input-group-0"
                                        class="col-sm-4"
                                        :label="content.riskCalcQuestion1.label"
                                        label-for="input-0">
                            <b-form-input
                              id="input-1"
                              type="number"
                              min="0"
                              :state="validateState('age')"
                              v-model="$v.formModel.age.$model"
                              required
                              :placeholder="content.riskCalcQuestion1.placeHolder"
                              aria-describedby="age-live-feedback"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="!validateState('age')" class="error-feedback" id="age-live-feedback">This is a required field.</b-form-invalid-feedback>
                          </b-form-group>
                          <b-form-group id="input-group-1"
                                        class="col-sm-4"
                                        :label="content.riskCalcQuestion2.label"
                                        label-for="input-1"
                                        :description="content.riskCalcQuestion2.description">
                            <v-select
                              id="input-1"
                              :options="options"
                              v-model="$v.formModel.education.$model"
                              :placeholder="content.riskCalcQuestion2.placeHolder"
                              :searchable="false"
                              :disabled="false"
                              :multiple="false"
                              aria-placeholder="Education"></v-select>
                            <b-form-invalid-feedback v-if="!validateState('education')" class="error-feedback" id="education-live-feedback">This is a required field.</b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="row">

                          <b-form-group id="input-group-2"
                                        class="col-sm-4"
                                        :label="content.riskCalcQuestion3.label"
                                        label-for="input-2">
                            <b-form-input
                              id="input-2"
                              type="number"
                              min="0"
                              v-model="$v.formModel.weight.$model"
                              required
                              :placeholder="content.riskCalcQuestion3.placeHolder"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="!validateState('weight')" class="error-feedback" id="weight-live-feedback">This is a required field.</b-form-invalid-feedback>
                          </b-form-group>
                          <b-form-group id="input-group-3"
                                        class="col-sm-8"
                                        :label="content.riskCalcQuestion4.label"
                                        label-for="input-3">
                            <b-form-input
                              class="field-inline"
                              id="input-3"
                              type="number"
                              min="0"
                              v-model="$v.formModel.heightFeet.$model"
                              required
                              :placeholder="content.riskCalcQuestion4.placeHolderFt"
                            ></b-form-input>

                            <b-form-input
                              class="field-inline"
                              v-model="$v.formModel.heightInches.$model"
                              id="input-13"
                              type="number"
                              min="0"
                              required
                              :placeholder="content.riskCalcQuestion4.placeHolderIn"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="(!validateState('heightInches') || !validateState('heightFeet')) && this.$v.formModel.heightInches.maxValue" class="error-feedback" id="height-live-feedback">At least one of the fields has to be filled</b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if=" false " class="error-feedback" id="height-live-feedback">At least one of the fields has to be filled</b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="this.$v.formModel.heightInches !=='' && !this.$v.formModel.heightInches.maxValue" class="error-feedback" id="height-live-feedback">value in inches should be lower than 12</b-form-invalid-feedback>
                          </b-form-group>

                        </div>

                        <div class="row">
                          <b-form-group id="input-group-4"
                                        class="col-sm-4"
                                        :label="content.riskCalcQuestion5.label">
                            <b-form-radio-group
                              v-model="$v.formModel.COPD.$model"
                              :options="['No', 'Yes']"
                            ></b-form-radio-group>
                            <b-form-invalid-feedback v-if="!validateState('COPD')" class="error-feedback" id="copd-live-feedback">This is a required field.</b-form-invalid-feedback>
                          </b-form-group>
                          <b-form-group id="input-group-5"
                                        class="col-sm-4"
                                        :label="content.riskCalcQuestion6.label">
                            <b-form-radio-group
                              v-model="$v.formModel.personalHistory.$model"
                              :options="['No', 'Yes']"
                            ></b-form-radio-group>
                            <b-form-invalid-feedback v-if="!validateState('personalHistory')" class="error-feedback" id="personalHistory-live-feedback">This is a required field.</b-form-invalid-feedback>
                          </b-form-group>
                          <b-form-group id="input-group-6"
                                        class="col-sm-4"
                                        :label="content.riskCalcQuestion7.label">
                            <b-form-radio-group
                              v-model="$v.formModel.familyHistory.$model"
                              :options="['No', 'Yes']"
                            ></b-form-radio-group>
                            <b-form-invalid-feedback v-if="!validateState('familyHistory')" class="error-feedback" id="familyHistory-live-feedback">This is a required field.</b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="row">
                          <b-form-group id="input-group-7"
                                        class="col-sm-4"
                                        label-for="input-7"
                                        :label="content.riskCalcQuestion8.label">
                            <v-select id="input-7"
                                      v-model="$v.formModel.race.$model"
                                      :placeholder="content.riskCalcQuestion8.placeHolder"
                                      :options="raceOptions"
                                      :searchable="false"
                                      :disabled="false"
                                      :multiple="false"
                                      aria-placeholder="Race"></v-select>
                            <b-form-invalid-feedback v-if="!validateState('race')" class="error-feedback" id="familyHistory-live-feedback">This is a required field.</b-form-invalid-feedback>
                          </b-form-group>
                          <b-form-group id="input-group-9"
                                        class="col-sm-4"
                                        label-for="input-9"
                                        :label="content.riskCalcQuestion9.label"
                          >
                            <v-select v-model="$v.formModel.smokingStatus.$model"
                                      id="input-9"
                                      :placeholder="content.riskCalcQuestion9.placeHolder"
                                      :options="smokingStatusOptions"
                                      :searchable="false"
                                      :disabled="false"
                                      :multiple="false"
                                      aria-placeholder="Smoking Status"></v-select>
                            <b-form-invalid-feedback v-if="!validateState('smokingStatus')" class="error-feedback" id="smokingStatus-live-feedback">This is a required field.</b-form-invalid-feedback>
                          </b-form-group>
                          <b-form-group id="input-group-12"
                                        class="col-sm-4"
                                        v-if="formModel.smokingStatus && formModel.smokingStatus.value === 0 "
                                        :label="content.riskCalcQuestion10.label"
                                        label-for="input-12">
                            <b-form-input
                              id="input-12"
                              type="number"
                              min="0"
                              v-model="$v.formModel.yearsSinceQuitSmoking.$model"
                              required
                              :placeholder="content.riskCalcQuestion10.placeHolder"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="!validateState('yearsSinceQuitSmoking')" class="error-feedback" id="yearsSinceQuitSmoking-live-feedback">This is a required field.</b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="row">
                          <b-form-group id="input-group-10"
                                        class="col-sm-6"
                                        :label="content.riskCalcQuestion11.label"
                                        label-for="input-10">
                            <b-form-input
                              id="input-10"
                              v-model="$v.formModel.averageCigsSmoked.$model"
                              type="number"
                              min="0"
                              required
                              :placeholder="content.riskCalcQuestion11.placeHolder"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="!validateState('averageCigsSmoked')" class="error-feedback" id="averageCigsSmoked-live-feedback">This is a required field.</b-form-invalid-feedback>
                          </b-form-group>
                          <b-form-group id="input-group-11"
                                        class="col-sm-4"
                                        :label="content.riskCalcQuestion12.label"
                                        label-for="input-11">
                            <b-form-input
                              id="input-11"
                              type="number"
                              min="0"
                              v-model="$v.formModel.durationSmokedYears.$model"
                              required
                              :placeholder="content.riskCalcQuestion12.placeHolder"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="!validateState('durationSmokedYears')" class="error-feedback" id="durationSmokedYears-live-feedback">This is a required field.</b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div id="submit-button-grp">
                          <b-button  class="btn btn-primary score-form-btn" v-on:click="calculateProbability" id="calculate-score" ><span>Calculate Probability</span></b-button>
                          <b-button  class="btn btn-warning score-form-btn" id="clear-form" @click.prevent="clearForm()" ><span>Clear Form</span></b-button>
                          <span id="probability-result" v-if="this.probability !== 0">{{content.riskCalcResultText}} &nbsp; <span> {{(this.probability*100).toFixed(2)}}%</span>  </span>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="section">
              <section id="screnningPage" class="container body-content">
                <div class="row toppading">
                  <div class="col-sm-12">
                    <h2>{{content.locationsSectionTitle}}</h2>
                  </div>
                </div>
                <div class="row toppading2">
                  <div class="col-sm-8">
                    <div style="margin-top:15px; margin-right: 20px;">
                      <p>
                        {{content.locationsSectionInstruct}}
                      </p>

                    </div>
                  </div>
                  <div class="col-sm-12 text-center">
                    <div class="border-primary justify-content-center" >

                      <div class="row">
                        <div class="col-md-12">
                          <a id="open-site-locator1-btn" v-on:click="buttonClicked('acraccreditation.org')" href="https://www.acraccreditation.org/accredited-facility-search" target="_blank" type="button" class="btn btn-primary btn-secondary section-btn">
                            <!--<span>Site Locator 1</span>-->
                            <img :src="this.baseURL+'images/ACR-icon-sprite-@2x.png'">
                          </a>
                          <p class="buttons-note">{{content.ACRLocationInstructions}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="row toppading2" style="text-align: center;padding-bottom: 20px;">
              <div class="col-sm-12">
                <div style="margin-top: 15px; margin-right: 20px;">
                  <a href="#" v-on:click.prevent="showInfoModal" target="_blank" style="font-size: 15px;">Click here for more information about this site</a> <br>
                  <!-- <a href="#" v-on:click.prevent="showPrivacyPolicyModal" target="_blank" style="font-size: 15px;">Privacy Policy</a><br> -->
                  <a href="/PrivacyPolicy"  target="_blank" style="font-size: 15px;">Privacy Policy</a>
                </div>
              </div>
            </div>

        </div>

  </div>
</template>

<script>
// @ is an alias to /src
import 'vue-select/src/scss/vue-select.scss'
import vSelect from 'vue-select'
import VideoPlayer from '@/components/VideoPlayer'
import PrivacyPolicy from '@/components/PrivacyPolicy'
import { validationMixin } from 'vuelidate'
import axios from 'axios'
import { required, minLength, requiredUnless, requiredIf, maxValue } from 'vuelidate/lib/validators'
import 'video.js/dist/video-js.css'
import content from '../../public/json/content.json'
import md5 from 'md5'
// import { Modal } from 'ant-design-vue'

import Vue from 'vue'
import Toasted from 'vue-toasted'
Vue.use(Toasted, { })
const validAccessCode = function (value, vm) {
  // console.log(md5(value))
  // console.log(vm)
  return md5(value) === vm.correctAccessCode
}

export default {
  name: 'NjhHOME',
  mounted: function () {
    // console.log(content)
    // set default
    this.consentForm.stateFromURL = true

    if (this.isValidState(this.$route.params.userState)) {
      this.consentForm.stateFromURL = true
    }
    // if referral valid, assign it to origin
    // https://lungscreen.health/referral/userState

    this.consentForm.origin = 'NJH'

    // this.consentForm.origin = this.possibleOrigins.includes(this.$route.params.referral) ? this.$route.params.referral : null
    // if (!this.consentForm.origin && this.consentForm.stateFromURL) {
    //  this.consentForm.origin = 'print'
    // }

    this.consentForm.isReferred = 'Yes'

    if (this.consentForm.origin == null) this.consentForm.origin = 'organic'

    this.consentForm.timeStamp = Date.now()
    const currentdate = new Date()
    const datetime = currentdate.getDate() + '/' +
                (currentdate.getMonth() + 1) + '/' +
                currentdate.getFullYear() + ' @ ' +
                currentdate.getHours() + ':' +
                currentdate.getMinutes() + ':' +
                currentdate.getSeconds()

    // this.showModal()
    this.consentForm.datetime = datetime
    this.consent()
    // Vue.toasted.show(this.disclaimerMessage, { action: this.toastActions, fullWidth: true, position: 'bottom-center' })
  },
  components: {
    VideoPlayer,
    PrivacyPolicy,
    'v-select': vSelect
  },
  watch: {
    formModel: {
      handler: function (v) {
        if (!this.riskFormDirty) {
          // console.log('form changed')
          this.sendEvent({ source: 'RISK_FORM', category: 'NJH', action: 'FORM_CHANGED' })
          this.riskFormDirty = true
        }
        return true
      },
      deep: true
    },
    eligibilityFormModel: {
      handler: function (v) {
        if (!this.elligibilityFormDirty) {
          this.elligibilityFormDirty = true
          this.sendEvent({ source: 'NJH_ELLIGIBILTY_FORM', category: 'NJH', action: 'FORM_CHANGED' })
          // console.log('eligibilityFormModel changed')
        }
        return true
      },
      deep: true
    }
  },
  methods: {
    isValidState (param) {
      let result = false
      if (!param) { return false }
      this.states.forEach(function (e) {
        if (param.toUpperCase() === e.short) {
          // console.log(param.toUpperCase() === e.short)
          this.consentForm.selectedState = e
          result = true
        }
      }, this)
      return result
    },
    consent () {
      if (this.consentStage === 2) {
        this.$modal.hide('disclaimer-modal')
        // window.ga(function (tracker) {
        //   window.clientId = tracker.get('clientId')
        // })
        this.consentForm.clientId = window.clientId
        this.consentForm.origin = 'NJH'
        const eventData = {
          hitType: 'event',
          eventCategory: 'NJH',
          eventAction: 'loaded',
          eventLabel: JSON.stringify(this.consentForm)
        }
        // window.ga('send', eventData)

        try {
          return axios.post(process.env.VUE_APP_API_BASE_URL + 'api/connect/logevent', null, { params: { payload: eventData } })
            .then(function (result) { return result })
            .catch(function (result) { return result })
        } catch (error) {
          // console.log(error)
        }
      }
    },
    // {source, category, action}
    sendEvent (eventObj) {
      const eventObject = {}
      eventObject.timeStamp = Date.now()
      const currentdate = new Date()
      const datetime = currentdate.getDate() + '/' +
                (currentdate.getMonth() + 1) + '/' +
                currentdate.getFullYear() + ' @ ' +
                currentdate.getHours() + ':' +
                currentdate.getMinutes() + ':' +
                currentdate.getSeconds()
      eventObject.datetime = datetime
      eventObject.source = eventObj.source
      // window.ga(function (tracker) {
      //   window.clientId = tracker.get('clientId')
      // })
      eventObject.clientId = window.clientId
      // window.ga('send', {
      //   hitType: 'event',
      //   eventCategory: 'Button Clicked',
      //   eventAction: 'clicked',
      //   eventLabel: JSON.stringify(eventObject)
      // })
      let eventData = {}
      eventData = {
        hitType: 'event',
        eventCategory: eventObj.category,
        eventAction: eventObj.action,
        eventLabel: JSON.stringify(eventObject)
      }
      try {
        return axios.post(process.env.VUE_APP_API_BASE_URL + 'api/connect/logevent', null, { params: { payload: eventData } })
          .then(function (result) { return result })
          .catch(function (result) { return result })
      } catch (error) {
        // console.log(error)
      }
    },
    buttonClicked (button) {
      const eventObject = {}
      eventObject.timeStamp = Date.now()
      const currentdate = new Date()
      const datetime = currentdate.getDate() + '/' +
                (currentdate.getMonth() + 1) + '/' +
                currentdate.getFullYear() + ' @ ' +
                currentdate.getHours() + ':' +
                currentdate.getMinutes() + ':' +
                currentdate.getSeconds()
      eventObject.datetime = datetime
      eventObject.sourceBtn = button
      // window.ga(function (tracker) {
      //   window.clientId = tracker.get('clientId')
      // })
      eventObject.clientId = window.clientId
      const eventData = {
        hitType: 'event',
        eventCategory: 'NJH',
        eventAction: 'clicked',
        eventLabel: JSON.stringify(eventObject)
      }
      // window.ga('send', eventData)

      try {
        return axios.post(process.env.VUE_APP_API_BASE_URL + 'api/connect/logevent', null, { params: { payload: eventData } })
          .then(function (result) { return result })
          .catch(function (result) { return result })
      } catch (error) {
        // console.log(error)
      }
    },
    handleVideoEvent: function (event) {
      // console.log(event)
      if (event === 'PROGRESS') {
        if ((Date.now() - this.lastProgress) > 30000) {
          // console.log('sending progress event')
          this.progressTime = this.lastProgress === 0 ? 0 : this.progressTime + 30000
          this.lastProgress = Date.now()
          let eventData = {}
          const eventObject = {}
          eventObject.timeStamp = Date.now()
          const currentdate = new Date()
          const datetime = currentdate.getDate() + '/' +
                (currentdate.getMonth() + 1) + '/' +
                currentdate.getFullYear() + ' @ ' +
                currentdate.getHours() + ':' +
                currentdate.getMinutes() + ':' +
                currentdate.getSeconds()
          eventObject.datetime = datetime
          eventObject.progressTime = this.progressTime
          // window.ga(function (tracker) {
          //   window.clientId = tracker.get('clientId')
          // })
          eventObject.clientId = window.clientId
          eventObject.origin = 'NJH'
          eventData = {
            hitType: 'event',
            eventCategory: 'NJH',
            eventAction: 'VIDEO_' + event,
            eventLabel: JSON.stringify(eventObject)
          }
          try {
            return axios.post(process.env.VUE_APP_API_BASE_URL + 'api/connect/logevent', null, { params: { payload: eventData } })
              .then(function (result) { return result })
              .catch(function (result) { return result })
          } catch (error) {
            // console.log(error)
          }
        }
      } else {
        let eventData = {}
        this.videoFinished = event === 'FINISHED'
        const eventObject = {}
        eventObject.timeStamp = Date.now()
        const currentdate = new Date()
        const datetime = currentdate.getDate() + '/' +
                (currentdate.getMonth() + 1) + '/' +
                currentdate.getFullYear() + ' @ ' +
                currentdate.getHours() + ':' +
                currentdate.getMinutes() + ':' +
                currentdate.getSeconds()
        eventObject.datetime = datetime
        eventObject.originReferer = this.$route.params.parameter
        this.selectedState = this.isValidState(eventObject.originReferer)
        eventObject.origin = 'NJH'
        // window.ga(function (tracker) {
        //   window.clientId = tracker.get('clientId')
        // })
        eventObject.clientId = window.clientId
        eventObject.selectedState = this.selectedState
        eventData = {
          hitType: 'event',
          eventCategory: 'NJH',
          eventAction: 'VIDEO_' + event,
          eventLabel: JSON.stringify(eventObject)
        }
        try {
          return axios.post(process.env.VUE_APP_API_BASE_URL + 'api/connect/logevent', null, { params: { payload: eventData } })
            .then(function (result) { return result })
            .catch(function (result) { return result })
        } catch (error) {
        // console.log(error)
        }
      }
    },
    beforeOpen () {
      this.hasBugs = true
      this.modalCloseAttempt = false
    },
    beforeClose (event) {
      if (this.hasBugs) {
        this.modalCloseAttempt = true
        /*
        Stopping close event execution
        */
        event.cancel()
      }
    },
    showModal () {
      this.$modal.show('disclaimer-modal')
    },
    showInfoModal () {
      this.$modal.show('information-modal')
    },
    showPrivacyPolicyModal () {
      this.$modal.show('privacy-modal')
    },
    closeInfoModal () {
      this.$modal.hide('information-modal')
    },
    closePrivacyModal () {
      this.$modal.hide('privacy-modal')
    },
    handleConsent (e) {
      // console.log(e)
      this.modalVisible = false
    },
    clearForm: function () {
      this.height = {
        heightInches: null,
        heightFeet: null
      }
      this.formModel = {
        age: null,
        education: null,
        weight: null,
        height: null,
        COPD: null,
        personalHistory: null,
        familyHistory: null,
        race: null,
        smokingStatus: null,
        averageCigsSmoked: null,
        durationSmokedYears: null,
        yearsSinceQuitSmoking: null

      }
      Vue.nextTick(() => {
        this.$v.formModel.$reset()
      })
    },
    clearEligibilityForm: function () {
      this.eligibilityFormModel = {
        currentSmokingStatus: null,
        yearsSinceQuitSmoking: null,
        yearsSmoked: null,
        cigPerDay: null,
        age: null,
        lungCancerDiagnosis: null
      }
      this.eligibilityResult = {
        calculated: false,
        currentSmokingStatus: null,
        yearsSmoked: null,
        cigPerDay: null,
        age: null,
        lungCancerDiagnosis: null,
        packetYear: 0,
        icons: {
          currentSmokingStatus: ['fas', 'thumbs-up'],
          lungCancerDiagnosis: ['fas', 'thumbs-up'],
          packetYear: ['fas', 'thumbs-up'],
          age: ['fas', 'thumbs-up']
        }
      }
      Vue.nextTick(() => {
        this.$v.eligibilityFormModel.$reset()
      })
    },
    calculateProbability: function () {
      this.$v.formModel.$touch()
      if (this.$v.formModel.$anyError) {
        return
      }

      const modelConstant = -4.532506

      const coefficients = {
        age: 0.0778868,
        education: -0.0812744,
        BMI: -0.0274194,
        COPD: 0.3553063,
        personalHistory: 0.4589971,
        familyHistory: 0.587185,
        race: [
          0,
          0.3944778,
          -0.7434744,
          -0.466585,
          0,
          1.027152
        ],
        smokingStatus: 0.2597431,
        averageCigsSmoked: -1.822606,
        durationSmokedYears: 0.0317321,
        yearsSinceQuitSmoking: -0.0308572
      }

      const BMI = (this.cleanFormModel.weight / Math.pow(this.cleanFormModel.height, 2)) * 703

      let raceConrtibution = 0
      if (this.cleanFormModel.race) {
        coefficients.race.forEach(function (e, i) {
          raceConrtibution += e * this.cleanFormModel.race[i]
        }, this)
      }

      this.cleanFormModel.yearsSinceQuitSmoking = this.cleanFormModel.smokingStatus === 0 ? this.cleanFormModel.yearsSinceQuitSmoking : 0 // should be 0 if current smoker

      const contributionToEstimate = {
        age: (this.cleanFormModel.age - 62) * coefficients.age,
        education: (this.cleanFormModel.education - 4) * coefficients.education,
        BMI: (BMI - 27) * coefficients.BMI,
        COPD: this.cleanFormModel.COPD * coefficients.COPD,
        personalHistory: this.cleanFormModel.personalHistory * coefficients.personalHistory,
        familyHistory: this.cleanFormModel.familyHistory * coefficients.familyHistory,
        race: raceConrtibution,
        smokingStatus: this.cleanFormModel.smokingStatus * coefficients.smokingStatus,
        averageCigsSmoked: coefficients.averageCigsSmoked * (Math.pow(this.cleanFormModel.averageCigsSmoked / 10, -1) - 0.4021541613),
        durationSmokedYears: (this.cleanFormModel.durationSmokedYears - 27) * coefficients.durationSmokedYears,
        yearsSinceQuitSmoking: (this.cleanFormModel.yearsSinceQuitSmoking - 10) * coefficients.yearsSinceQuitSmoking
      }
      this.intermediateValues = contributionToEstimate

      const XB = this.sumObject(contributionToEstimate) + modelConstant
      const expXB = Math.exp(XB)
      this.probability = Number.parseFloat(expXB / (1 + expXB)).toPrecision(5)
      const eventObject = {}
      eventObject.timeStamp = Date.now()
      const currentdate = new Date()
      const datetime = currentdate.getDate() + '/' +
                (currentdate.getMonth() + 1) + '/' +
                currentdate.getFullYear() + ' @ ' +
                currentdate.getHours() + ':' +
                currentdate.getMinutes() + ':' +
                currentdate.getSeconds()
      eventObject.datetime = datetime
      eventObject.origin = 'NJH'
      // window.ga(function (tracker) {
      //   window.clientId = tracker.get('clientId')
      // })
      eventObject.clientId = window.clientId
      // window.ga('send', {
      //   hitType: 'event',
      //   eventCategory: 'NJH',
      //   eventAction: 'risk_calculated',
      //   eventLabel: JSON.stringify(eventObject)
      // })
      let eventData = {}
      eventData = {
        hitType: 'event',
        eventCategory: 'NJH',
        eventAction: 'risk_calculated',
        eventLabel: JSON.stringify(eventObject)
      }
      try {
        return axios.post(process.env.VUE_APP_API_BASE_URL + 'api/connect/logevent', null, { params: { payload: eventData } })
          .then(function (result) { return result })
          .catch(function (result) { return result })
      } catch (error) {
        // console.log(error)
      }
    },
    calculateEligibility: async function () {
      this.$v.eligibilityFormModel.$touch()
      if (this.$v.eligibilityFormModel.$anyError) {
        return
      }
      this.eligibilityResult.calculated = true

      this.eligibilityResult.age = this.eligibilityFormModel.age >= 50 && this.eligibilityFormModel.age <= 80
      this.eligibilityResult.icons.age = this.eligibilityResult.age ? ['fas', 'check-circle'] : ['fas', 'times-circle']

      this.eligibilityResult.currentSmokingStatus = this.eligibilityFormModel.currentSmokingStatus &&
                                                    (this.eligibilityFormModel.currentSmokingStatus === 'Yes' ||
                                                      (this.eligibilityFormModel.currentSmokingStatus === 'No, I quit' && this.eligibilityFormModel.yearsSinceQuitSmoking <= 15))
      this.eligibilityResult.icons.currentSmokingStatus = this.eligibilityResult.currentSmokingStatus ? ['fas', 'check-circle'] : ['fas', 'times-circle']

      this.eligibilityResult.packetYear = await Math.ceil(this.eligibilityFormModel.yearsSmoked * (this.eligibilityFormModel.cigPerDay / 20))
      this.eligibilityResult.icons.packetYear = this.eligibilityResult.packetYear >= 20 ? ['fas', 'check-circle'] : ['fas', 'times-circle']

      this.eligibilityResult.lungCancerDiagnosis = this.eligibilityFormModel.lungCancerDiagnosis === 'Yes'
      this.eligibilityResult.icons.lungCancerDiagnosis = !this.eligibilityResult.lungCancerDiagnosis ? ['fas', 'check-circle'] : ['fas', 'times-circle']

      const eventObject = {}
      eventObject.timeStamp = Date.now()
      const currentdate = new Date()
      const datetime = currentdate.getDate() + '/' +
                (currentdate.getMonth() + 1) + '/' +
                currentdate.getFullYear() + ' @ ' +
                currentdate.getHours() + ':' +
                currentdate.getMinutes() + ':' +
                currentdate.getSeconds()
      eventObject.datetime = datetime
      eventObject.origin = 'NJH'
      // window.ga(function (tracker) {
      //   window.clientId = tracker.get('clientId')
      // })
      eventObject.clientId = window.clientId
      let eventData = {}
      eventData = {
        hitType: 'event',
        eventCategory: 'NJH',
        eventAction: 'eligibility_calculated',
        eventLabel: JSON.stringify(eventObject)
      }
      try {
        return axios.post(process.env.VUE_APP_API_BASE_URL + 'api/connect/logevent', null, { params: { payload: eventData } })
          .then(function (result) { return result })
          .catch(function (result) { return result })
      } catch (error) {
        // console.log(error)
      }
    },
    sumObject: function sum (obj) {
      let sum = 0
      for (const el in obj) {
        sum += parseFloat(obj[el])
      }
      return sum
    },
    validateState (name) {
      const { $dirty, $error } = this.$v.formModel[name]
      return $dirty ? !$error : true
    },
    validateConsentFormState (name) {
      const { $dirty, $error } = this.$v.consentForm[name]
      return $dirty ? !$error : true
    },
    validateEligibilityFormState (name) {
      const { $dirty, $error } = this.$v.eligibilityFormModel[name]
      return $dirty ? !$error : true
    },
    validAccessCode (value) {
      return md5(value) === this.consentForm.correctAccessCode
    },
    validateAccessCode (name) {
      const { $dirty, $error, validAccessCode } = this.$v.accessCodeForm[name]
      return $dirty ? validAccessCode : true
    }

  },
  computed: {
    cleanFormModel: function () {
      return {
        age: this.formModel.age ? this.formModel.age : 0,
        education: this.formModel.education ? this.formModel.education.value : 0,
        weight: this.formModel.weight ? this.formModel.weight : 0,
        height: parseInt(this.formModel.heightFeet ? this.formModel.heightFeet : 0) * 12 + parseInt(this.formModel.heightInches ? this.formModel.heightInches : 0),
        COPD: this.formModel.COPD ? (this.formModel.COPD === 'Yes' ? 1 : 0) : 0,
        personalHistory: this.formModel.personalHistory ? (this.formModel.personalHistory === 'Yes' ? 1 : 0) : 0,
        familyHistory: this.formModel.familyHistory ? (this.formModel.familyHistory === 'Yes' ? 1 : 0) : 0,
        race: this.formModel.race ? this.formModel.race.value : [0, 0, 0, 0, 0, 0],
        smokingStatus: this.formModel.smokingStatus ? this.formModel.smokingStatus.value : 0,
        averageCigsSmoked: this.formModel.averageCigsSmoked ? this.formModel.averageCigsSmoked : 0,
        durationSmokedYears: this.formModel.durationSmokedYears ? this.formModel.durationSmokedYears : 0,
        yearsSinceQuitSmoking: this.formModel.yearsSinceQuitSmoking ? this.formModel.yearsSinceQuitSmoking : 0
      }
    },
    baseURL: () => process.env.VUE_APP_BASE_ROUTER_URL
  },
  mixins: [validationMixin],
  data: function () {
    return {
      quitLineState: '',
      LCSState: '',
      origin: '',
      lastProgress: 0,
      progressTime: 0,
      possibleOrigins: ['text', 'email', 'qr', 'web', 'print', 'demo', 'social'],
      excludedStates: ['MI', 'mi'],
      consentStage: 2,
      states: [
        {
          short: 'AL',
          name: 'Alabama',
          country: 'US'
        },
        {
          short: 'AK',
          name: 'Alaska',
          country: 'US'
        },
        {
          short: 'AZ',
          name: 'Arizona',
          country: 'US'
        },
        {
          short: 'AR',
          name: 'Arkansas',
          country: 'US'
        },
        {
          short: 'CA',
          name: 'California',
          country: 'US'
        },
        {
          short: 'CO',
          name: 'Colorado',
          country: 'US'
        },
        {
          short: 'CT',
          name: 'Connecticut',
          country: 'US'
        },
        {
          short: 'DC',
          name: 'District of Columbia',
          alt: ['Washington DC', 'Washington D.C.'],
          country: 'US'
        },
        {
          short: 'DE',
          name: 'Delaware',
          country: 'US'
        },
        {
          short: 'FL',
          name: 'Florida',
          country: 'US'
        },
        {
          short: 'GA',
          name: 'Georgia',
          country: 'US'
        },
        {
          short: 'HI',
          name: 'Hawaii',
          country: 'US'
        },
        {
          short: 'ID',
          name: 'Idaho',
          country: 'US'
        },
        {
          short: 'IL',
          name: 'Illinois',
          country: 'US'
        },
        {
          short: 'IN',
          name: 'Indiana',
          country: 'US'
        },
        {
          short: 'IA',
          name: 'Iowa',
          country: 'US'
        },
        {
          short: 'KS',
          name: 'Kansas',
          country: 'US'
        },
        {
          short: 'KY',
          name: 'Kentucky',
          country: 'US'
        },
        {
          short: 'LA',
          name: 'Louisiana',
          country: 'US'
        },
        {
          short: 'ME',
          name: 'Maine',
          country: 'US'
        },
        {
          short: 'MD',
          name: 'Maryland',
          country: 'US'
        },
        {
          short: 'MA',
          name: 'Massachusetts',
          country: 'US'
        },
        {
          short: 'MI',
          name: 'Michigan',
          country: 'US'
        },
        {
          short: 'MN',
          name: 'Minnesota',
          country: 'US'
        },
        {
          short: 'MS',
          name: 'Mississippi',
          country: 'US'
        },
        {
          short: 'MO',
          name: 'Missouri',
          country: 'US'
        },
        {
          short: 'MT',
          name: 'Montana',
          country: 'US'
        },
        {
          short: 'NE',
          name: 'Nebraska',
          country: 'US'
        },
        {
          short: 'NV',
          name: 'Nevada',
          country: 'US'
        },
        {
          short: 'NH',
          name: 'New Hampshire',
          country: 'US'
        },
        {
          short: 'NJ',
          name: 'New Jersey',
          country: 'US'
        },
        {
          short: 'NM',
          name: 'New Mexico',
          country: 'US'
        },
        {
          short: 'NY',
          name: 'New York',
          country: 'US'
        },
        {
          short: 'NC',
          name: 'North Carolina',
          country: 'US'
        },
        {
          short: 'ND',
          name: 'North Dakota',
          country: 'US'
        },
        {
          short: 'OH',
          name: 'Ohio',
          country: 'US'
        },
        {
          short: 'OK',
          name: 'Oklahoma',
          country: 'US'
        },
        {
          short: 'OR',
          name: 'Oregon',
          country: 'US'
        },
        {
          short: 'PA',
          name: 'Pennsylvania',
          country: 'US'
        },
        {
          short: 'RI',
          name: 'Rhode Island',
          country: 'US'
        },
        {
          short: 'SC',
          name: 'South Carolina',
          country: 'US'
        },
        {
          short: 'SD',
          name: 'South Dakota',
          country: 'US'
        },
        {
          short: 'TN',
          name: 'Tennessee',
          country: 'US'
        },
        {
          short: 'TX',
          name: 'Texas',
          country: 'US'
        },
        {
          short: 'UT',
          name: 'Utah',
          country: 'US'
        },
        {
          short: 'VT',
          name: 'Vermont',
          country: 'US'
        },
        {
          short: 'VA',
          name: 'Virginia',
          country: 'US'
        },
        {
          short: 'WA',
          name: 'Washington',
          country: 'US'
        },
        {
          short: 'WV',
          name: 'West Virginia',
          country: 'US'
        },
        {
          short: 'WI',
          name: 'Wisconsin',
          country: 'US'
        },
        {
          short: 'WY',
          name: 'Wyoming',
          country: 'US'
        },
        {
          short: 'AS',
          name: 'American Samoa',
          country: 'US'
        },
        {
          short: 'GU',
          name: 'Guam',
          country: 'US'
        },
        {
          short: 'MP',
          name: 'Northern Mariana Islands',
          country: 'US'
        },
        {
          short: 'PR',
          name: 'Puerto Rico',
          country: 'US'
        },
        {
          short: 'UM',
          name: 'United States Minor Outlying Islands',
          country: 'US'
        },
        {
          short: 'VI',
          name: 'Virgin Islands',
          country: 'US'
        }],
      showTopDisclaimer: true,
      modalCloseAttempt: false,
      consentClicked: false,
      bugCount: 0,
      message: '',
      hasBugs: false,
      modalVisible: false,
      videoFinished: false,
      consentForm: {
        stateFromURL: false,
        isReferred: '',
        selectedState: '',
        origin: ''
      },
      accessCodeForm: {
        accessCode: '',
        correctAccessCode: 'b460018dd087d5653df1cabb02a27c36' // 232020
      },
      content: content,
      toastActions: [{
        text: 'Got it!',
        onClick: (e, toastObject) => {
          toastObject.goAway(0)
        }
      }],
      videoOptions: {
        // autoplay: true,
        controls: true,
        responsive: true,
        sources: [
          {
            src:
                process.env.VUE_APP_BASE_ROUTER_URL + 'videos/vid_v2/playlist.m3u8',
            type: 'application/x-mpegURL'
          }
        ],
        poster: process.env.VUE_APP_BASE_ROUTER_URL + 'images/vid_thumnail2.jpg'
      },
      formModel: {
        age: null,
        education: null,
        weight: null,
        heightInches: null,
        heightFeet: null,
        COPD: null,
        personalHistory: null,
        familyHistory: null,
        race: null,
        smokingStatus: null,
        averageCigsSmoked: null,
        durationSmokedYears: null,
        yearsSinceQuitSmoking: null
      },
      options: [
        { label: 'Less than high school grad', value: 1 },
        { label: 'High School grad', value: 2 },
        { label: 'Post high school training', value: 3 },
        { label: 'Some college', value: 4 },
        { label: 'College grad', value: 5 },
        { label: 'Postgraduate/Professional', value: 6 }

      ],
      raceOptions: [
        { label: 'White', value: [1, 0, 0, 0, 0, 0] },
        { label: 'Black', value: [0, 1, 0, 0, 0, 0] },
        { label: 'Hispanic', value: [0, 0, 1, 0, 0, 0] },
        { label: 'Asian', value: [0, 0, 0, 1, 0, 0] },
        { label: 'Native Hawaiian/Pacific islander ', value: [0, 0, 0, 0, 1, 0] },
        { label: 'American indian', value: [0, 0, 0, 0, 0, 1] }

      ],
      smokingStatusOptions: [
        { label: 'Former-smoker', value: 0 },
        { label: 'Current-smoker', value: 1 }

      ],
      eligibilityResult: {
        calculated: false,
        currentSmokingStatus: null,
        yearsSmoked: null,
        cigPerDay: null,
        age: null,
        lungCancerDiagnosis: null,
        packetYear: 0,
        icons: {
          currentSmokingStatus: ['fas', 'thumbs-up'],
          lungCancerDiagnosis: ['fas', 'thumbs-up'],
          packetYear: ['fas', 'thumbs-up'],
          age: ['fas', 'thumbs-up']
        }
      },
      probability: 0,
      eligibilityFormModel: {
        currentSmokingStatus: null,
        yearsSinceQuitSmoking: null,
        yearsSmoked: null,
        cigPerDay: null,
        age: null,
        lungCancerDiagnosis: null
      },
      intermediateValues: {}
    }
  },

  validations: {
    consentForm: {
      isReferred: { required },
      selectedState: { required }
    },
    // accessCodeForm: {
    //   accessCode: { required, validAccessCode }
    // },
    formModel: {
      age: { required },
      education: { required },
      weight: { required },
      /* height: { required }, */
      COPD: { required },
      personalHistory: { required },
      familyHistory: { required },
      race: { required },
      smokingStatus: { required },
      averageCigsSmoked: { required },
      durationSmokedYears: { required },
      yearsSinceQuitSmoking: {
        required: requiredIf(function (nestedModel) {
          return this.formModel.smokingStatus === null || this.formModel.smokingStatus.value === 0
        })
      },
      heightFeet: {
        required: requiredIf(function (nestedModel) {
          // console.log('heightFeet required ' + this.formModel.heightInches)
          return this.formModel.heightInches === null || this.formModel.heightInches === ''
        })
      },
      heightInches: {
        required: requiredIf(function (nestedModel) {
          // console.log((this.$v.formModel.heightInches.maxValue))
          return this.formModel.heightFeet === null || this.formModel.heightFeet === ''
        }),
        maxValue: maxValue(12)
      }
    },
    /* height: {
      heightFeet: {
        required: requiredIf(function (nestedModel) {
          return this.height.heightInches === null
        })
        // ,maxValue: 12
      },
      heightInches: {
        required: requiredIf(function (nestedModel) {
          return this.height.heightFeet === null
        })
      }
    }, */
    eligibilityFormModel: {
      currentSmokingStatus: { required },
      yearsSinceQuitSmoking: {
        required: requiredIf(function (nestedModel) {
          return this.eligibilityFormModel.currentSmokingStatus !== 'Yes'
        })
      },
      yearsSmoked: { required },
      cigPerDay: { required },
      age: { required },
      lungCancerDiagnosis: { required }
    }
  }

}

</script>
<style lang="scss" scoped>
  .privacy-modal{
    width:80%;
    left: 10%;
    height: 80%;
    overflow-y: scroll;
  }
</style>
