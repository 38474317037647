import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimesCircle as fasTimesCircle, faCheckCircle as fasCheckCircle, faCoffee, faThumbsUp as fasThumbsUp, faThumbsDown as fasThumbsDown, faHeart as fasHeart, faTrash, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle, faCheckCircle, faHeart, faThumbsUp, faThumbsDown, faMinusSquare as farMinusSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BootstrapVue from 'bootstrap-vue'
import vmodal from 'vue-js-modal'
library.add(fasTimesCircle, faTimesCircle, faCheckCircle, fasCheckCircle, faThumbsUp, faCoffee, faThumbsDown, faHeart, fasHeart, fasThumbsUp, fasThumbsDown, faTrash, faMinusSquare, farMinusSquare)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(vmodal, { dialog: true })

// console.log(process.env.BASE_URL)
Vue.use(BootstrapVue)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
